import { template as template_e3b0d8522a7249be9622cffa309f33f0 } from "@ember/template-compiler";
const FKLabel = template_e3b0d8522a7249be9622cffa309f33f0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
