import { template as template_84c6d2d2d70d49dd8b03dced8ea97c61 } from "@ember/template-compiler";
const FKText = template_84c6d2d2d70d49dd8b03dced8ea97c61(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
