import { template as template_b83a78a718924a59bca4aabca4bf2e4f } from "@ember/template-compiler";
const FKControlMenuContainer = template_b83a78a718924a59bca4aabca4bf2e4f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
