import { template as template_700c87b69b194cd39169927e3dfbcbf1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_700c87b69b194cd39169927e3dfbcbf1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
