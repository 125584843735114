import { template as template_af37d5a4d0664b54a87d0e8a4e44b21e } from "@ember/template-compiler";
const WelcomeHeader = template_af37d5a4d0664b54a87d0e8a4e44b21e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
